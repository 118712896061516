<template>
  <financial-list
    v-model="currentPage"
    :header-data="TABLE_HEADERS"
    :events="TABLE_EVENTS"
    :data="list"
    itemRefKey='id'
    :disabled="loading"
    :items-per-page="itemsPerPage"
    @page-changed="loadDeals"
    :total="total"
    :no-items-message="translations.pending_configs.empty_list"
    @row-details="() => {}"/>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';

export default {
  components: {
    FinancialList,
  },
  name: 'DealsWithDealsWithPendingConfig',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.pending_configs.id, 'id'),
      associateHeaderDataItem(this.translations.pending_configs.sfdc_opportunity_id, 'opportunity_id'),
      associateHeaderDataItem(this.translations.pending_configs.deal_name, 'name'),
      associateHeaderDataItem(this.translations.pending_configs.close_date, 'close_date'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.pending_configs.edit_details,
        icon: 'edit-2',
        redirectLink: id => `/commissions/deals/${id}`,
        redirectLinkToNewPage: true,
      },
    };
  },
  data() {
    return {
      translations: translations.commissions.deals,
      appliedFilters: {},
      list: [],
      loading: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    async applyFilters(filters, page = 1) {
      this.currentPage = page;
      this.appliedFilters = filters;

      await this.loadDeals();
    },
    async loadDeals() {
      this.loading = true;

      try {
        const searchFilters = {
          view: 'pending',
          ...this.appliedFilters,
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'id',
          order_descending: true,
        };

        const { deals, total } = await service.getDeals(searchFilters);

        this.list = deals.map(deal => {
          deal.close_date = formatDate(deal.close_date);
          return deal;
        });
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_list}: ${parseResponseError(err)}`);
      }

      this.loading = false;
    },
  },
};
</script>
